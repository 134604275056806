import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const Title = makeShortcode("Title");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Mobile Pattern Introduction`}</h2>
    <p>{`Our login and registration process is user-friendly and quick. It includes pages for registering, resetting forgotten passwords, and setting new passwords. Users can log in using a username and password, email, phone number, or their social media accounts. This ensures easy access to all app services, tailored to your preferred method.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Use case pattern:`}</strong>{` In this pattern, various types of alternative login methods are presented based on the primary key. Please be aware, in every page there is Pros & Cons that should be read carefully.`}</p>
    </InlineNotification>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Case : Mobile Number as Primary Key</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Username as Primary Key</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Email Address as Primary Key</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Telkom (NIK) as Primary Key</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Third Party (SSO) as Primary Key</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <InlineNotification kind='warning' mdxType="InlineNotification">
      <p><strong parentName="p">{`Important Information:`}</strong>{` This pattern complies with UU PDP principles, ensuring top-notch consent, data protection, and privacy.`}</p>
    </InlineNotification>
    <p>{`When collecting and managing customer data, it is important to know how to create access for users to consent. Providing the ability for customers to give consent is one of the priorities
for their experience, explaining why their data is being collected, who the collectors are, and how the data would be managed.`}</p>
    <DoDontRow className="legion-pattern-login-register-row" mdxType="DoDontRow">
      <DoDont type="do" aspectRatio="16:9" colLg="6" captionTitle="Do" caption="When asking for data permissions, users must act explicitly on consenting to the permissions. A checklist will be helpful to inform the users." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/guideline-do-uu-pdp.png",
          "alt": "UU PDP Compliance Agreements Do"
        }}></img>
      </DoDont>
      <DoDont type="dont" aspectRatio="16:9" colLg="6" captionTitle="Don't" caption="Assuming the users would agree to the permissions by ticking the checklist by default." mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/guideline-dont-uu-pdp.png",
          "alt": "UU PDP Compliance Agreements Donts"
        }}></img>
      </DoDont>
    </DoDontRow>
    <p>{`An essential aspect of data management and user consent is how businesses identify and authenticate users. Implementing a method that is both user-friendly and secure is critical for maintaining trust and efficiency. Various methods can be used, each with advantages and use cases. It is also crucial to ensure that the login and registration processes comply with customer consent regulations and the Personal Data Protection Act (UU PDP).`}</p>
    <div className="divi" />
    <h2>{`Case : Mobile Number as Primary Key`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This pattern is the most recommended considering the principle of mobile first, where currently users already have a mobile number. It will provide faster clarity, simple, and secure for users . There is no mechanism for forgetting a mobile number, other than contacting customer service. However, it’s better if combined with email as a secondary key to send verification to email when the user forgets the mobile number.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a website user, I want to log in using a mobile number, so that once my mobile number is verified I can log in easily.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the onboarding process`}</li>
      <li parentName="ul">{`Language used is in English`}</li>
      <li parentName="ul">{`Primary key is the mobile number`}</li>
      <li parentName="ul">{`Provide the appropriate verification process for the mobile number login option`}</li>
      <li parentName="ul">{`Provide negative case options (input error, etc.) in the flow if needed`}</li>
      <li parentName="ul">{`Provide contact us if there are problems in the login, registration or verification process`}</li>
    </ul>
    <br />
    <ImageCard title="Login Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=2044%3A81543" actionIcon="arrowRight" subTitleColor="dark" subTitle="Mobile Number as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-1.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <br />
    <ImageCard title="Register Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1905%3A75824" actionIcon="arrowRight" subTitleColor="dark" subTitle="Mobile Number as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-2.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <br />
    <ImageCard title="Contact Us Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/E3euPEWPUYNLu8Wdh6QpluSz/Pattern-Login-%26-Register?node-id=2310%3A82436" actionIcon="arrowRight" subTitleColor="dark" subTitle="Mobile Number as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-3.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Simple login and registration process & have an alternative method of otp delivery (whatsapp if any)" captionTitle="Do" caption="Use this, if the product wants to make Mobile Number is Primary Key, but doesn’t require email as a secondary key" mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : There is no mechanism for forgetting the mobile number, other than contacting customer services" captionTitle="Don't" caption="Don’t use this, if the product wants to make Mobile Number is Primary Key and Email is Secondary Key," mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Username as Primary Key`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This pattern will provide convenience in the login and registration process. There is no pre-verified account (low level of security) and no mechanism for forgetting a username, other than contacting customer service. However, it’s better if combined with email or mobile number as a secondary key to send verification to email when the user forgets the mobile number.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a website user, I want to log in using a username, I can log in easily.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the onboarding process`}</li>
      <li parentName="ul">{`Language used is in Bahasa`}</li>
      <li parentName="ul">{`Primary key is the username with password for login verification`}</li>
      <li parentName="ul">{`Provide negative case options (input error, etc.) in the flow if needed`}</li>
      <li parentName="ul">{`Provide contact us if there are problems in the login, registration or verification process`}</li>
    </ul>
    <br />
    <ImageCard title="Login Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1909%3A76858" actionIcon="arrowRight" subTitleColor="dark" subTitle="Username as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-4.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <br />
    <ImageCard title="Register Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1933%3A88953" actionIcon="arrowRight" subTitleColor="dark" subTitle="Username as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-5.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Simple registration process" captionTitle="Do" caption="Use this, if the product wants to make Username is Primary Key, but the product has most types of users who don’t prioritize email or mobile number as the primary key" mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : Low level of security, no pre-verified account & without mechanism for forgetting the username, other than contacting customer services" captionTitle="Don't" caption="Don’t use this, if the product has most types of users who have an email or mobile number as the primary key, verification is very important for security" mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Email Address as Primary Key`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`Almost all mobile devices in the world use email as the primary key and email is also used as a medium of communication that is continuously used today, by using this pattern the security factor would be better maintained. It will provide faster clarity, simple, and secure for users . There is no mechanism for forgetting a mobile number, other than contacting customer service. However, it’s better if combined with mobile number as a secondary key to send verification to email when the user forgets the email.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a website user, I want to log in using a mobile number, so that once my mobile number is verified I can log in easily.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the onboarding process`}</li>
      <li parentName="ul">{`Language used is in English`}</li>
      <li parentName="ul">{`Primary key is the mobile number`}</li>
      <li parentName="ul">{`Provide the appropriate verification process for the mobile number`}</li>
      <li parentName="ul">{`Provide negative case options (input error, etc.) in the flow if needed`}</li>
      <li parentName="ul">{`Provide contact us if there are problems in the login or verification process`}</li>
    </ul>
    <br />
    <ImageCard title="Login Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1907%3A74453" actionIcon="arrowRight" subTitleColor="dark" subTitle="Email Address as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-6.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <br />
    <ImageCard title="Register Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1909%3A76296" actionIcon="arrowRight" subTitleColor="dark" subTitle="Email Address as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-7.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="1:1" colLg="6" text="Pros : Secure and become a 2-step security alternative when combined with other secondary keys." captionTitle="Do" caption="If the product wants to make email as the primary key with an economical OTP verification method, could combine with mobile number as secondary key for better option and give users control and must implement stay login system to avoid users being asked for an OTP email continuously every time they call the application." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="1:1" colLg="6" text="Cons : Broken login and registration journey, because user would be directed to see their OTP on email and there is no autofilled mechanism given for this method, users being asked for an OTP email continuously if not implement stay login mechanism. Could be pain point and if a user's email is hacked, it will most likely be a security vulnerability for the product." captionTitle="Don't" caption="Avoid using it if the primary key is mobile number. If the email is used as a secondary key, then it’s enough to provide an email verification link and then continue to use the OTP via the mobile number for a better security factor, using it if email is treat as username that combined with password/pin and avoid using it if the same email is used within login/register by Google." mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Telkom (NIK) as Primary Key`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This pattern provides easy login for Telkom’s internal ecosystem. Does not have a registration mechanism and a solution for forgetting passwords, everything is done in the main Telkom Portal application with NIK.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a website user, I want to log in using NIK, so I don’t need to create another account anymore.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the onboarding process`}</li>
      <li parentName="ul">{`Language used is in English`}</li>
      <li parentName="ul">{`Primary key is the NIK`}</li>
      <li parentName="ul">{`Provide negative case options (input error, etc.) in the flow if needed`}</li>
      <li parentName="ul">{`Provide contact us if there are problems in the login process.`}</li>
    </ul>
    <br />
    <ImageCard title="Login Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1937%3A77927" actionIcon="arrowRight" subTitleColor="dark" subTitle="Portal Telkom (NIK) as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-8.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Secure and easy login for Telkom’s internal ecosystem." captionTitle="Do" caption="If the end user of the product is a Telkom employee with a NIK and could combine with other key as secondary key for better option and give users control." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : Does not have a registration mechanism and a solution for forgetting passwords, everything is done in the main Telkom Portal application." captionTitle="Don't" caption="Avoid using it if the product does not require a data connection to Telkom's internal ecosystem." mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Third Party (SSO) as Primary Key`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This pattern provides convenience in logging in with various social media accounts as single sign on (SSO). Use it when the product does not require user-specific data and emphasizes user acquisition.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a website user, I want to log in using a social media account that I have, so I don’t need to create another account anymore.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the onboarding process`}</li>
      <li parentName="ul">{`Language used is in English`}</li>
      <li parentName="ul">{`Primary key is the Social Media Account`}</li>
      <li parentName="ul">{`Provide the appropriate verification process`}</li>
      <li parentName="ul">{`Provide negative case options (input error, etc.) in the flow if needed`}</li>
    </ul>
    <br />
    <ImageCard title="Login Flow " titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/2j5jQBiXmJBUP4SkBFzGrB/Pattern-Login-%26-Register?node-id=1918%3A76746" actionIcon="arrowRight" subTitleColor="dark" subTitle="Third Party (SSO) as Primary Key" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/login-and-register/login-mobile-9.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Easy login for end-user." captionTitle="Do" caption="If the product does not require user-specific data and emphasizes user acquisition and could combine with product primary key to gather specific information." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : Not all data can be obtained except by adding an intercept page to retrieve primary data from users." captionTitle="Don't" caption="If the product is used only for certain groups and you want to maintain super tight security." mdxType="DoDont"></DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      